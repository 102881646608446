<!--
 * @Author: dongjia
 * @Date: 2021-06-23 17:39:02
 * @LastEditTime: 2022-01-04 15:33:04
 * @LastEditors: aleaner
 * @Description: 会员管理-转账记录
 * @FilePath: \organization-admin\views\member\transfer-record.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <TransferListFilter
      v-model="filterForm"
      :ids="selectArr"
      :page="pageData.current_page"
      :uploadFilter="ok"
    />
    <list-tabs
      v-model="filterForm.tab"
      :isLoading="showLoading"
      :tabs="tabArr"
      @tab-click="handleClick"
    >
    </list-tabs>
    <el-table
      style="margin-top: 20px"
      :data="tbody"
      class="thead-light"
      v-loading="showLoading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <!-- 勾选 -->
      <el-table-column
        class="select-check"
        fixed
        type="selection"
        width="55"
      ></el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" :min-width="80" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="checkDetail(scope.row.id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in thead"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.minWidth"
        show-overflow-tooltip
        :sortable="item.sortable"
      >
        <template slot-scope="scope">
          <span v-if="item.prop === 'images_count'">{{
            (scope.row[item.prop] + ' 张图') | placeholder
          }}</span>
          <span v-else>{{ scope.row[item.prop] | placeholder }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    >
    </Pagination>
    <TransferDetailDialog
      @refreshList="updateList"
      v-model="openDetailDialog"
      :id="current_id"
    />
  </div>
</template>

<script>
import TransferListFilter from '../../components/Member/TransferListFilter'
import TransferDetailDialog from '../../components/Member/TransferDetailDialog.vue'
import { transferList } from '../../api/member/member-transfer'
import Pagination from '@/base/components/Default/Pagination'
export default {
  name: 'circle-category',
  data() {
    return {
      tabArr: [
        { label: '全部', name: 'all' },
        { label: '待审核', name: 'checking' },
        { label: '已通过', name: 'checked' },
        { label: '不通过', name: 'unchecked' },
      ],
      filterForm: {
        keyword: '',
        nickname: '',
        start_time: -1,
        end_time: -1,
        page_size: 15,
        tab: 'all',
      },
      pageData: {},
      thead: [
        { label: 'ID', prop: 'id', minWidth: 70 },
        { label: '提交人', prop: 'user_nickname', minWidth: 110 },
        { label: '提交职务', prop: 'position_name', minWidth: 110 },
        { label: '说明', prop: 'remark', minWidth: 150 },
        {
          label: '图片',
          prop: 'images_count',
          minWidth: 100,
        },
        {
          label: '提交时间',
          prop: 'create_time',
          minWidth: 160,
          sortable: true,
        },
        { label: '审批状态', prop: 'check_text', minWidth: 150 },
      ],
      tbody: [],
      selectArr: [],
      current_id: 0,
      openDetailDialog: false,
    }
  },
  components: {
    Pagination,
    TransferListFilter,
    TransferDetailDialog,
  },
  created() {
    this.getTransferList(this.filterForm)
  },
  methods: {
    handleClick(e) {
      console.log(e)
      // this.filterForm.tab = this.filters[e.name]
      this.getTransferList(this.filterForm)
    },
    // 查看转账详情
    checkDetail(id) {
      this.current_id = id
      this.openDetailDialog = true
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getTransferList(this.filterForm)
    },
    updateList() {
      this.getTransferList({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.getTransferList(this.filterForm)
    },
    changPage(data) {
      this.filterForm = { ...this.filterForm, ...data }
      this.getTransferList(this.filterForm)
    },
    getTransferList(requestData) {
      this.showLoading = true
      this.pageData = {}
      transferList(requestData)
        .then((res) => {
          const { data } = res
          this.tbody = data.data
          this.pageData = data
          this.showLoading = false
        })
        .catch((err) => {
          this.showLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
